// locales/client.ts
'use client';

import { createI18nClient } from 'next-international/client';

const {
    useI18n,
    useScopedI18n,
    I18nProviderClient,
    useChangeLocale,
    useCurrentLocale,
} = createI18nClient({
    en: () => import('./locales/en'),
    it: () => import('./locales/it'),
});

const _useScopedI18n = useScopedI18n as <Scope extends any>(
    scope: Scope,
) => ReturnType<typeof useScopedI18n> | any;

export {
    _useScopedI18n,
    I18nProviderClient,
    useChangeLocale,
    useCurrentLocale,
    useI18n,
};
