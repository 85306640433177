import(/* webpackMode: "eager" */ "/src/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/src/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/src/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/src/app/src/app/app.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/app/src/shared/components/AppLoadingComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProviderClient"] */ "/src/app/src/shared/i18n/client.ts");
